html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.kMwMqD {
  width: 100%;
}

.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  left: auto !important;
}
.MuiButtonBase-root:hover {
  background-color: rgba(247, 221, 212, 0.2);
}
.MuiAccordion-root::before {
  background-color: unset !important;
}
.MuiTimelineItem-root::before {
  display: none !important;
}
.gm-style-cc {
  display: none;
}
.imageStyle {
  border-radius: 50%;
}
.pac-container {
  z-index: 100042;
}

/* Hide arrows on number fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
